const config = {
  aws_project_region: "eu-central-1",
  aws_cognito_region: "eu-central-1",
  aws_user_pools_id: "eu-central-1_R7ooBIq22",
  aws_user_pools_web_client_id: "16dd385t01cii9a20mo11viqdj",
  oauth: {
    domain: "auth.test.dwm.navify.com",
    scope: [
      "phone",
      "email",
      "profile",
      "openid",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: `${window.location?.origin}/`,
    redirectSignOut: `${window.location?.origin}/`,
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_appsync_graphqlEndpoint: "https://api.test.dwm.navify.com/graphql",
  aws_appsync_region: "eu-central-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  pipeline_id: "18164753",
  commit_hash: "6f7c3c7e",
};

export const federated = {
  googleClientId: "Roche",
};

export default config;
